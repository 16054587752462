import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
} from '@mui/material';
import api from './api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const HubSettings = () => {
  const { hubId } = useParams();
  const [hub, setHub] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [sftpSettings, setSftpSettings] = useState({
    driver: 'sftp',
    host: '',
    username: '',
    password: '',
    port: 22,
  });
  const [excelSettings, setExcelSettings] = useState({
    filename: '',
    uploadLimit: null,
    columnTitles: false, 
  });
  const [excelTable, setExcelTable] = useState({
    row1: Array(6).fill(''), 
    row2: Array(6).fill(''), 
  });
  const [productCounts, setProductCounts] = useState({});
  const [totalProducts, setTotalProducts] = useState(0);
  const [settingsSaved, setSettingsSaved] = useState(false);
  const dropdownOptionsRow1 = [
    'PartNo',
    'Mfc',
    'Qty',
    'Desc',
    'Price',
    'Category',
    'Alternative_part_number',
    'Product_condition',
    'Weight',
    'Webshop_URL',
    'Datasheet_URL',
    'Image_URL',
    'Lead_time',
    'Warranty',
    'Custom_1',
    'Custom_2',
    'SKU',
    'GTIN',
  ];

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const allOwners = JSON.parse(localStorage.getItem('owners')) || [];
        const supplierList = allOwners.filter(owner => owner.state === "supplier");
        setSuppliers(supplierList);
  
        const hubSuppliers = JSON.parse(localStorage.getItem('hub_supplier')) || [];
        const currentHub = hubSuppliers.find(hub => hub.id == hubId);
  
        if (currentHub) {
          setHub({ name: currentHub.name, acronym: currentHub.acronym });
          setSelectedSuppliers(currentHub.suppliers.map(supplier => supplier.id));
          const counts = {};
          supplierList.forEach((supplier) => {
            counts[supplier.id] = Number(supplier.product_count) || 0;
          });
          setProductCounts(counts);

          const initialTotal = currentHub.suppliers
            .map((supplier) => Number(counts[supplier.id]) || 0)
            .reduce((sum, count) => sum + count, 0);
          setTotalProducts(initialTotal);
          const settings = currentHub.settings || {};
          setSftpSettings({
            driver: settings.sftp?.driver || 'sftp',
            host: settings.sftp?.host || '',
            username: settings.sftp?.username || '',
            password: '',
            port: settings.sftp?.port || 22,
          });
          setExcelSettings({
            filename: settings.excel?.filename || '',
            uploadLimit: settings.excel?.uploadLimit ,
            columnTitles: settings.excel?.columnTitles || false,
          });
          setExcelTable({
            row1: settings.excel?.table?.row1 || Array(6).fill(''),
            row2: settings.excel?.table?.row2 || Array(6).fill(''),
          });
        }
      } catch (error) {
        console.error("Failed to load suppliers from localStorage:", error);
      }
    };
  
    fetchSettings();
  }, [hubId]);
  const handleTestConnection = async () => {
    try {
      const response = await api.post(`/api/test-sftp-connection/${hubId}`);
      if (response.data.success) {
        toast.success('SFTP connection successful!');
      } else {
        toast.error('SFTP connection failed. Please check your settings.');
      }
    } catch (error) {
      toast.error('Error testing SFTP connection. Please verify your settings.');
      console.error('Error testing SFTP connection:', error.message);
    }
  };
  const handleSupplierToggle = (supplierId) => {
    setSelectedSuppliers((prev) => {
      const updatedSelection = prev.includes(supplierId)
        ? prev.filter((id) => id !== supplierId)
        : [...prev, supplierId];

      const newTotal = updatedSelection
        .map((id) => productCounts[id] || 0)
        .reduce((sum, count) => sum + count, 0);
      setTotalProducts(newTotal);

      return updatedSelection;
    });
  };

  const handleExcelTableChange = (row, index, value) => {
    setExcelTable((prev) => ({
      ...prev,
      [row]: prev[row].map((item, i) => (i === index ? value : item)),
    }));
  };
  const handleSave = async () => {
    try {
      if (excelSettings.uploadLimit && totalProducts > excelSettings.uploadLimit) {
        toast.error(
          `Total products (${totalProducts}) exceed the upload limit (${excelSettings.uploadLimit}). Please adjust your selections.`
        );
        return; 
      }
      await api.put(`/api/hubs/${hubId}/settings`, {
        name: hub.name,
        acronym: hub.acronym,
        suppliers: selectedSuppliers,
        sftpSettings,
        excelSettings: {
          ...excelSettings,
          table: excelTable, 
        },
      });
      const response = await api.get('/api/hubs/settings');
      const { owners, hub_supplier } = response.data;
      localStorage.setItem('owners', JSON.stringify(owners));
      localStorage.setItem('hub_supplier', JSON.stringify(hub_supplier));
      setSettingsSaved(true);
      toast.success("Hub settings saved successfully!");
    } catch (error) {
      toast.error("Failed to save hub settings.");
    }
  };
  
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {hub.name} Hub Settings
      </Typography>
      <Paper sx={{ padding: 2, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>Hub Details</Typography>
        <TextField
          label="Hub Name"
          value={hub.name}
          onChange={(e) => setHub((prev) => ({ ...prev, name: e.target.value }))}
          fullWidth
          sx={{ mb: 2 }}
          variant="outlined"
        />
        <TextField
          label="Hub Acronym"
          value={hub.acronym}
          onChange={(e) => setHub((prev) => ({ ...prev, acronym: e.target.value }))}
          fullWidth
          sx={{ mb: 2 }}
          variant="outlined"
        />
      </Paper>
{/* Supplier Selector */}
<Paper sx={{ padding: 2, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>Supplier Selector</Typography>
        {suppliers.map((supplier) => (
          <FormControlLabel
            key={supplier.id}
            control={
              <Checkbox
                checked={selectedSuppliers.includes(supplier.id)}
                onChange={() => handleSupplierToggle(supplier.id)}
              />
            }
            label={`${supplier.acronym} (${productCounts[supplier.id] || 0} products)`}
          />
        ))}
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Total Products: {totalProducts}
        </Typography>
      </Paper>

      {/* SFTP Settings */}
      <Paper sx={{ padding: 2, marginBottom: 3 }}>
  <Typography variant="h6" gutterBottom>SFTP Settings</Typography>
  <TextField
    label="Driver"
    value={sftpSettings.driver}
    onChange={(e) => setSftpSettings({ ...sftpSettings, driver: e.target.value })}
    fullWidth
    sx={{ mb: 2 }}
    variant="outlined"
    slotProps={{
      inputLabel: { shrink: true }
    }}
  />
  <TextField
    label="Host"
    value={sftpSettings.host}
    onChange={(e) => setSftpSettings({ ...sftpSettings, host: e.target.value })}
    fullWidth
    sx={{ mb: 2 }}
    variant="outlined"
    slotProps={{
      inputLabel: { shrink: true }
    }}
  />
  <TextField
    label="Username"
    value={sftpSettings.username}
    onChange={(e) => setSftpSettings({ ...sftpSettings, username: e.target.value })}
    fullWidth
    sx={{ mb: 2 }}
    variant="outlined"
    slotProps={{
      inputLabel: { shrink: true }
    }}
  />
  <TextField
    label="Password"
    type="password"
    value={sftpSettings.password}
    onChange={(e) => setSftpSettings({ ...sftpSettings, password: e.target.value })}
    fullWidth
    sx={{ mb: 2 }}
    variant="outlined"
    slotProps={{
      inputLabel: { shrink: true }
    }}
  />
  <TextField
    label="Port"
    type="number"
    value={sftpSettings.port}
    onChange={(e) => setSftpSettings({ ...sftpSettings, port: e.target.value })}
    fullWidth
    sx={{ mb: 2 }}
    variant="outlined"
    slotProps={{
      inputLabel: { shrink: true }
    }}
  />
</Paper>
      {/* Excel Settings */}
      <Paper sx={{ padding: 2, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>Excel Settings</Typography>
        <TextField
          label="Filename"
          value={excelSettings.filename}
          onChange={(e) => setExcelSettings({ ...excelSettings, filename: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
          variant="outlined"
        />
        <TextField
          label="Upload Limit"
          type="number"
          value={excelSettings.uploadLimit}
          onChange={(e) => setExcelSettings({ ...excelSettings, uploadLimit: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
          variant="outlined"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excelSettings.columnTitles}
              onChange={(e) => setExcelSettings({ ...excelSettings, columnTitles: e.target.checked })}
            />
          }
          label="Column titles"
        />

        {/* Conditional Rendering of Excel Table */}
        <TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'auto' }}>
  <Table>
    <TableBody>
      {/* First Row - Text Fields (only shown if columnTitles is true) */}
      {excelSettings.columnTitles && (
        <TableRow>
          {excelTable.row1.map((value, index) => (
            <TableCell key={`row1-${index}`}>
              <TextField
                value={value}
                onChange={(e) => handleExcelTableChange('row1', index, e.target.value)}
                fullWidth
              />
            </TableCell>
          ))}
        </TableRow>
      )}

      {/* Second Row - Dropdowns */}
      <TableRow>
        {excelTable.row2.map((value, index) => (
          <TableCell key={`row2-${index}`}>
            <TextField
              select
              value={value}
              onChange={(e) => handleExcelTableChange('row2', index, e.target.value)}
              fullWidth
            >
              {dropdownOptionsRow1.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        ))}
        {/* Add "+" button */}
        {excelTable.row2.length < 18 && (
          <TableCell>
            <Button
              onClick={() =>
                setExcelTable((prev) => ({
                  ...prev,
                  row1: [...prev.row1, ...Array(12).fill('')], // Always add 12 columns to row1
                  row2: [...prev.row2, ...Array(12).fill('')], // Add 12 columns to row2
                }))
              }
              variant="contained"
              color="primary"
            >
              +
            </Button>
          </TableCell>
        )}
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start',gap:2, marginTop: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Settings
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleTestConnection}
          disabled={!settingsSaved} 
        >
          Test SFTP Connection
        </Button>
      </Box>
    </Box>
  );
};

export default HubSettings;
