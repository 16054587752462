import axios from 'axios';

const api = axios.create({
 baseURL: 'https://syncapi.syncrel.com', 
 //baseURL: 'http://local.laravel-project.com/', 
  withCredentials : true,
  withXSRFToken : true
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
  
export default api;
