import React, { useContext, useState, useEffect } from 'react';
import {
  Box, TextField, Button, Typography, Tab, Tabs, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, IconButton
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import api from './api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { OwnersContext } from './OwnersContext';
const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState(''); // For user registration
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { owners, updateOwners } = useContext(OwnersContext);// List of all owners
  const [ownerName, setOwnerName] = useState(''); // For adding/editing owners
  const [acronym, setAcronym] = useState('');
  const [editingOwner, setEditingOwner] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchOwners();
  }, []);

  const fetchOwners = async () => {
    try {
      const response = await api.get('/api/hubs/settings');
      const { owners, hub_supplier } = response.data;
      localStorage.setItem('owners', JSON.stringify(owners));
      localStorage.setItem('hub_supplier', JSON.stringify(hub_supplier));
      updateOwners(owners);
    } catch (error) {
      toast.error("Error fetching owners.");
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      toast.error('Passwords do not match!');
      return;
    }

    const formData = { name, email, password, password_confirmation: passwordConfirmation };

    api.post('/api/auth/register', formData)
      .then(() => {
        toast.success('User registered successfully!');
        setName(''); setEmail(''); setPassword(''); setPasswordConfirmation('');
      })
      .catch(() => toast.error('Failed to register user. Please try again.'));
  };

  const handleAddOrUpdateOwner = (e) => {
    e.preventDefault();

    const state = activeTab === 1 ? 'supplier' : 'hub';
    const ownerData = { name: ownerName, acronym, state };

    if (editingOwner) {
      api.put(`/api/owners/${editingOwner.id}`, ownerData)
        .then(() => {
          setEditingOwner(null);
          setOwnerName(''); setAcronym('');
          toast.success(`${state.charAt(0).toUpperCase() + state.slice(1)} updated successfully!`);
          fetchOwners();
        })
        .catch(() => toast.error(`Failed to update ${state}.`));
    } else {
      api.post('/api/owners', ownerData)
        .then(() => {
          toast.success(`${state.charAt(0).toUpperCase() + state.slice(1)} added successfully!`);
          setOwnerName(''); setAcronym('');
          fetchOwners();
        })
        .catch(() => toast.error(`Failed to add ${state}.`));
    }
  };

  const handleEditOwner = (owner) => {
    if (owner.state === 'hub') {
      navigate(`/hub-settings/${owner.id}`);
    } else {
      navigate(`/supplier-settings/${owner.id}`);
    }
  };

  const handleDeleteOwner = (id) => {
    const state = activeTab === 1 ? 'supplier' : 'hub';
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this ${state}? This action cannot be undone.`
    );
    if (confirmDelete) {
      api.delete(`/api/owners/${id}`)
        .then(() => {
          toast.success(`${state.charAt(0).toUpperCase() + state.slice(1)} deleted successfully!`);
          fetchOwners();
        })
        .catch(() => toast.error(`Failed to delete ${state}.`));
    }
  };

  const filteredOwners = owners.filter((owner) => {
    if (activeTab === 1) return owner.state === 'supplier';
    if (activeTab === 2) return owner.state === 'hub';
    return false;
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Users" />
        <Tab label="Suppliers" />
        <Tab label="Hubs" />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ mt: 4, width: 500 }}>
          <Typography variant="h6" gutterBottom>Register New User</Typography>
          <form onSubmit={handleRegister}>
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth required sx={{ mb: 2 }} />
            <TextField label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth required sx={{ mb: 2 }} />
            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth required sx={{ mb: 2 }} />
            <TextField label="Confirm Password" type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} fullWidth required sx={{ mb: 2 }} />
            <Button type="submit" variant="contained" color="primary">Register User</Button>
          </form>
        </Box>
      )}

      {(activeTab === 1 || activeTab === 2) && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {editingOwner ? `Edit ${activeTab === 1 ? 'Supplier' : 'Hub'}` : `Add New ${activeTab === 1 ? 'Supplier' : 'Hub'}`}
          </Typography>
          <form onSubmit={handleAddOrUpdateOwner} style={{ marginBottom: '20px' }}>
            <TextField
              label={`${activeTab === 1 ? 'Supplier' : 'Hub'} Name`}
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              required
              sx={{ mr: 2, mb: 2 }}
            />
            <TextField
              label="Acronym"
              value={acronym}
              required
              onChange={(e) => setAcronym(e.target.value)}
              sx={{ mr: 2, mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">
              {editingOwner ? `Update ${activeTab === 1 ? 'Supplier' : 'Hub'}` : `Add ${activeTab === 1 ? 'Supplier' : 'Hub'}`}
            </Button>
          </form>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Acronym</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOwners.map((owner) => (
                  <TableRow key={owner.id}>
                    <TableCell>{owner.name}</TableCell>
                    <TableCell>{owner.acronym}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEditOwner(owner)}><Edit /></IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteOwner(owner.id)}><Delete /></IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default Settings;
