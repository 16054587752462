import React, { useEffect, useState } from "react";
import {  Modal, TextField } from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Button,
  Paper,
} from '@mui/material';
import {  Typography, Box, Select, MenuItem } from '@mui/material';
import api from './api';
import { useLocation } from "react-router-dom";
import SearchBar from "./searchBar";
import AddProductForm from "./AddProductForm";

import SearchTable from "./searchTable";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Pagination({ page, setPage, total }) {
  const totalPages = Math.ceil(total / 10);
  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value);
    setPage(newPage);
  };
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
        sx={{ marginRight: 2 }}
      >
        Previous
      </Button>

      <Typography component="span" sx={{ marginRight: 1 }}>
        Page
      </Typography>

      <Select
        id="page-select"
        value={page}
        onChange={handlePageChange}
        sx={{ minWidth: 60, marginRight: 1 }}
      >
        {Array.from({ length: totalPages }, (_, i) => (
          <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
          </MenuItem>
        ))}
      </Select>

      <Typography component="span" sx={{ marginRight: 2 }}>
        of {totalPages}
      </Typography>

      <Button
        variant="outlined"
        color="primary"
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
      >
        Next
      </Button>

       <Typography component="span" sx={{ marginLeft: 4 }}>
        Showing {total} products
      </Typography>
    </Box>
  );
}
function ProductsTable({  products, selectedProducts, setSelectedProducts, setEditProduct, setModal2IsOpen, setEditedQuantity }) {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const handleCheckboxChange = (e, product) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((p) => p.PartNo !== product.PartNo)
      );
    }
  };
  const handleEditClick = (product) => {
    setEditProduct(product);
    setEditedQuantity(product.Qty.toString());
    setModal2IsOpen(true); 
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Table sx={{ minWidth: 650 }} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>Manufacturer</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Added At</TableCell>
            <TableCell>Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              key={product.PartNo}
              onMouseEnter={() => setHoveredProductId(product.id)}
              onMouseLeave={() => setHoveredProductId(null)}
              hover
            >
              <TableCell>
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, product)}
                  inputProps={{ 'aria-label': `Select ${product.PartNo}` }}
                />
              </TableCell>
              <TableCell>{product.PartNo}</TableCell>
              <TableCell>{product.Mfc}</TableCell>
              <TableCell>
              <Box display="flex" justifyContent="flex-start" gap={2} alignItems="center">
                  
                  
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEditClick(product)}
                    >
                      Edit
                    </Button>
                    <span>{product.Qty}</span>
                </Box>
              </TableCell>
              <TableCell>{product.Desc}</TableCell>
              <TableCell>{product.created_at_human}</TableCell>
              <TableCell>{product.updated_at_human}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

function ProductsPage({ owner, reload  }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSrch, setPageSrch] = useState(1); 
  const [total, setTotal] = useState(0);
  const [totalS, setTotalS] = useState(0);
  const location = useLocation();
  const currentPageName = location.pathname;
  const pageIdentifier = currentPageName.split("/").pop();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);
  const [partNumber, setPartNumber] = useState("");
  const [results, setResults] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [editProduct, setEditProduct] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState("");
  useEffect(() => {
    const fetchProducts = () => {
      setIsLoading(true);
      api
        .get(`api/products?owner=${owner.acronym}&page=${page}`)
        .then((response) => {
          setProducts(response.data.data);
          setTotal(response.data.total || 0);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          toast.error("Error fetching products");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchProducts();
  }, [owner, page, reload ]);
  useEffect(() => {
    setPage(1); 
    setPageSrch(1); 
    setResults([]);
    setPartNumber("");
  }, [owner]);

  function debounce(fn, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  const debouncedHandleSearch = debounce(async () => {
    try {
      const response = await api.get(
        `api/search?partNumber=${partNumber}&pageName=${pageIdentifier}&page=${pageSrch}`
      );
      //console.log("API response data: ", response.data.data);
      setResults(Array.isArray(response.data.data) ? response.data.data : []);
      setTotalS(response.data.total || 1);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Error fetching data ");
    }
  }, 300);

  // UseEffect to handle page changes
  useEffect(() => {
    if (partNumber) debouncedHandleSearch();
  }, [partNumber, pageSrch]);
  const handleInputChange = (e) => {
    setPartNumber(e.target.value); // Update the partNumber triggering the debounced search
  };
  const removeProducts = async (selectedProducts) => {
    try {
      // Loop through the selected products
      for (let product of selectedProducts) {
        // Send a DELETE request for each product
        const response = await api.delete(`api/product/${product.id}`);
        toast.success(`Deleted product with ID ${product.id}`)
        // Log the response data
        console.log(`Deleted product with ID ${product.id}:`, response.data);
      }
      setProducts(products.filter(product => !selectedProducts.includes(product)));
    } catch (error) {
      // Log any errors that occur
      console.error('Error deleting products:', error);
      toast.error('Error deleting products');

    }
  };
  const handleAddProduct = async (newProduct) => {
    try {
      // Save the new product to the database or perform the desired action
      console.log("Adding new product:", newProduct);
      const response = await api.post("api/product", newProduct);
      setSuccessMessage(response.data.message);
      // Refresh the products list after adding the new product
    } catch (error) {
      console.error("Error adding new product:", error.message);
      toast.error("Error adding new product");

    }
  };
  const setProductsAndUpdate = async () => {
    try {
      const response = await api.get(`api/products?owner=${owner}&page=${page}`);
      setProducts(response.data.data);
      setTotal(response.data.total || 1);
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Error fetching products");
    }
  };
  const handleEditQuantity = async () => {
    try {
      const response = await api.put(`api/product/${editProduct.id}`, {
        Qty: editedQuantity,
      });
      console.log(`Updated product quantity for ID ${editProduct.id}:`, response.data);
      toast.success(`Updated product quantity for ID ${editProduct.id}`)
      await setProductsAndUpdate();
      const updatedResults = results.map((result) =>
      result.id === editProduct.id ? { ...result, Qty: editedQuantity } : result
    );
    
    setResults(updatedResults);
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((product) => product.id !== editProduct.id)
      );
      setEditProduct(null);
      setEditedQuantity("");
      setModal2IsOpen(false); // Close the modal after updating quantity
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };
  if (isLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <>
      {/* Top section with SearchBar and buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2, // Add some margin at the bottom
        }}
      >
        {/* Search Bar */}
        <SearchBar handleInputChange={handleInputChange} />

        {/* Add and Remove buttons */}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setModalIsOpen(true)}
            sx={{ mr: 2 }} // Add margin-right
          >
            Add Product
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => removeProducts(selectedProducts)}
          >
            Remove Selected Products
          </Button>
        </Box>
      </Box>

      {/* Modal for AddProductForm */}
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <AddProductForm onSubmit={handleAddProduct} successMessage={successMessage} />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }} // Margin top
            onClick={() => setModalIsOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {/* Pagination and Products Table */}
      {partNumber === "" && (
        <>
          <Pagination page={page} setPage={setPage} total={total} />
          <ProductsTable
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            setEditProduct={setEditProduct}
            setModal2IsOpen={setModal2IsOpen}
            setEditedQuantity={setEditedQuantity}
          />
        </>
      )}

      {/* Modal for editing product */}
      {editProduct && (
        <Modal
          open={modal2IsOpen}
          onClose={() => {
            setEditProduct(null);
            setModal2IsOpen(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>Edit quantity for {editProduct.PartNo}:</Typography>
            <TextField
              fullWidth
              type="number"
              value={editedQuantity}
              onChange={(e) => setEditedQuantity(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditQuantity}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setEditProduct(null);
                  setModal2IsOpen(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* Search results table */}
      {partNumber && (
        <>
        
          <Pagination
            page={pageSrch}
            setPage={setPageSrch}
            total={totalS}
            sx={{ mb: 2 }}
          />
          <SearchTable
            results={results}
            selectedResults={selectedProducts}
            setSelectedResults={setSelectedProducts}
            setEditProduct={setEditProduct}
            setModal2IsOpen={setModal2IsOpen}
            setEditedQuantity={setEditedQuantity}
          />
        </>
      )}
    </>
  );
}

export default ProductsPage;
