import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
  } from '@mui/material';
import { useParams } from 'react-router-dom';
import api from './api';
import { toast } from 'react-toastify';

const SupplierSettings = () => {
  const { supplierId } = useParams(); // Get the supplier ID from the URL
  const [supplierData, setSupplierData] = useState({
    name: '',
    acronym: '',
    info: '',
    settings:{
      uploadSettings:{
      row1: Array(6).fill(''), // Default empty array for row1
      row2: Array(6).fill(''), // Default empty array for row2
      row3: Array(6).fill(''),},
      newProductsScenario:true
    },
  });

  const dropdownOptionsRow1 = [
    'PartNo',
    'Mfc',
    'Qty',
    'Desc',
    'Price',
    'Category',
    'Alternative_part_number',
    'Product_condition',
    'Weight',
    'Webshop_URL',
    'Datasheet_URL',
    'Image_URL',
    'Lead_time',
    'Warranty',
    'Custom_1',
    'Custom_2',
    'SKU',
    'GTIN',
  ];

  const dropdownOptionsRow2 = Array.from({ length: 26 }, (_, i) => `${String.fromCharCode(65 + i)}`); // Column A to Z

  useEffect(() => {
    const fetchSupplierData = () => {
      try {
        // Get all suppliers from localStorage
        const allSuppliers = JSON.parse(localStorage.getItem('owners')) || [];
        // Find the specific supplier by ID
        const supplier = allSuppliers.find((s) => s.id === parseInt(supplierId));
  
        if (supplier) {
      
          const { name, acronym, info, settings } = supplier;
          // Set the supplier data, with fallback for uploadSettings
          setSupplierData({
            name: name || '',
            acronym: acronym || '',
            info: info || '',
            settings: {
              uploadSettings: {
                row1: settings?.uploadSettings?.row1 || Array(6).fill(''), // Default empty array for row1
                row2: settings?.uploadSettings?.row2 || Array(6).fill(''), // Default empty array for row2
                row3: settings?.uploadSettings?.row3 || Array(6).fill(''), // Default empty array for row3
              },
              newProductsScenario: settings?.newProductsScenario ?? true, // Default to true if undefined
            },
          });
        } else {
          toast.error('Supplier not found.');
        }
      } catch (error) {
        toast.error('Failed to fetch supplier data from localStorage.');
      }
    };
  
    fetchSupplierData();
  }, [supplierId]);

  const handleChange = (field, value) => {
    setSupplierData((prev) => {
      const keys = field.split('.'); // Split the nested keys
      const lastKey = keys.pop(); // Get the last key
      let updated = { ...prev };
  
      // Traverse to the correct nested level
      let nested = updated;
      keys.forEach((key) => {
        nested[key] = { ...nested[key] };
        nested = nested[key];
      });
  
      // Update the value at the correct level
      nested[lastKey] = value;
  
      return updated;
    });
  };
  const handleUploadSettingsChange = (row, index, value) => {
    setSupplierData((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        uploadSettings: {
          ...prev.settings.uploadSettings,
          [row]: prev.settings.uploadSettings[row].map((item, i) =>
            i === index ? value : item
          ),
        },
      },
    }));
  };

  const handleSave = async () => {
    try {
      await api.put(`/api/suppliers/${supplierId}/settings`, supplierData);
      const response = await api.get('/api/hubs/settings');
      const { owners, hub_supplier } = response.data;
      localStorage.setItem('owners', JSON.stringify(owners));
      localStorage.setItem('hub_supplier', JSON.stringify(hub_supplier));
      toast.success('Supplier settings saved successfully!');
    } catch (error) {
      toast.error('Failed to save supplier settings.');
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
       {supplierData.name} Supplier Settings
      </Typography>

      {/* Supplier Name and Acronym */}
      <Box sx={{ marginBottom: 3 }}>
        <TextField
          label="Supplier Name"
          value={supplierData.name}
          onChange={(e) => handleChange('name', e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Supplier Acronym"
          value={supplierData.acronym}
          onChange={(e) => handleChange('acronym', e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Info"
          value={supplierData.info}
          onChange={(e) => handleChange('info', e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />
      </Box>

      {/* Upload Settings */}
      <Typography variant="h6" gutterBottom>
        Upload Settings
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: 3, overflowX: 'auto' }}>
  <Table>
    <TableBody>
      {/* Row 1 - Dropdown for row1 */}
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', width: 20, textAlign: 'center', marginRight: 2 }}>Database</TableCell>
        {supplierData.settings.uploadSettings.row1.map((value, index) => (
          <TableCell key={`row1-${index}`}>
            <TextField
              select
              value={value}
              onChange={(e) => handleUploadSettingsChange('row1', index, e.target.value)}
              fullWidth
            >
                <MenuItem value="">
                 <em>None</em>{/* Empty option */}
              </MenuItem>
              {dropdownOptionsRow1.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        ))}
        {/* Add "+" button */}
        {supplierData.settings.uploadSettings.row1.length < 18 && (
          <TableCell>
          <Button
            onClick={() =>
              setSupplierData((prev) => ({
                ...prev,
                settings: {
                  ...prev.settings, // Spread the existing settings
                  uploadSettings: {
                    ...prev.settings.uploadSettings, // Spread the existing uploadSettings
                    row1: [...prev.settings.uploadSettings.row1, ...Array(12).fill('')], // Add 12 cells to row1
                    row2: [...prev.settings.uploadSettings.row2, ...Array(12).fill('')], // Add 12 cells to row2
                    row3: [...prev.settings.uploadSettings.row3, ...Array(12).fill('')], // Add 12 cells to row3
                  },
                },
              }))
            }
            variant="contained"
            color="primary"
          >
            +
          </Button>
        </TableCell>
        )}
      </TableRow>
      {/* Row 2 - Dropdown for row2 */}
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', width: 20, textAlign: 'center', marginRight: 2 }}>Excel</TableCell>
        {supplierData.settings.uploadSettings.row2.map((value, index) => (
          <TableCell key={`row2-${index}`}>
            <TextField
              select
              value={value}
              onChange={(e) => handleUploadSettingsChange('row2', index, e.target.value)}
              fullWidth
            >
                <MenuItem value="">
                <em>None</em> {/* Empty option */}
              </MenuItem>
              {dropdownOptionsRow2.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        ))}
        {/* Add "+" button */}
        <TableCell>
          {/* Keep empty for alignment */}
        </TableCell>
      </TableRow>
      {/* Row 3 - TextFields for Default */}
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', minWidth: 100, textAlign: 'center', marginRight: 2 }}>Default value</TableCell>
        {supplierData.settings.uploadSettings.row3.map((value, index) => (
          <TableCell key={`row3-${index}`}>
            <TextField
              value={value}
              onChange={(e) => handleUploadSettingsChange('row3', index, e.target.value)}
              fullWidth
            />
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>
{/* New Products Scenario */}
<Paper sx={{ padding: 2, marginBottom: 3 }}>
  <Typography variant="h6" gutterBottom>
    New Products Scenario
  </Typography>
  <FormControl component="fieldset">
    <FormLabel component="legend">Choose an option:</FormLabel>
    <RadioGroup
      value={supplierData.settings.newProductsScenario.toString()} // Convert boolean to string
      onChange={(e) => handleChange('settings.newProductsScenario', e.target.value === 'true')}
    >
      <FormControlLabel value="true" control={<Radio />} label="Overwrite everything" />
      <FormControlLabel value="false" control={<Radio />} label="Keep old products" />
    </RadioGroup>
  </FormControl>
</Paper>
      {/* Save Button */}
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save Settings
      </Button>
    </Box>
  );
};

export default SupplierSettings;
