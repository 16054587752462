import React, { useState } from 'react';
import { Box,Card, CardContent, Button, Typography } from '@mui/material';
import api from './api';

const SupplierDownload = ({ owner }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDownloadLastUpload = async () => {
    try {
      const response = await api.get(`/api/suppliers/${owner.id}/last-upload`, {
        responseType: 'blob',
      });
     
      const filename = owner.last_uploaded_file.replace('uploads/','');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setErrorMessage(null); 
    } catch (error) {
      console.error("Error downloading last uploaded file:", error.message);
      setErrorMessage("Last uploaded file not found or could not be downloaded.");
    }
  };

  const handleDownloadProductsReport = async () => {
    try {
      const response = await api.get(`/api/suppliers/${owner.id}/products-report`, {
        responseType: 'blob',
      });
      
      const filename = owner.acronym+'_products_report.xlsx';

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setErrorMessage(null); 
    } catch (error) {
      console.error("Error downloading products report:", error.message);
      setErrorMessage("Products report not found or could not be downloaded.");
    }
  };

  return (
<Card sx={{ width: 350, height: 200, padding: 2 }}>
  <CardContent>
    <Typography variant="h6" sx={{ mb: 2 }}>
      {owner.acronym} Excel Files
    </Typography>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownloadLastUpload}
        sx={{ width: "100%" }} 
      >
        Last Uploaded File
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleDownloadProductsReport}
        sx={{ width: "100%" }} 
      >
        Products Report
      </Button>
    </Box>
    {errorMessage && (
      <Typography variant="body2" color="error" sx={{ mt: 2 }}>
        {errorMessage}
      </Typography>
    )}
  </CardContent>
</Card>
  );
};

export default SupplierDownload;