import React, { useState, useContext } from "react";
import api from '../api'
import getCsrfCookie from "../getCsrfCookie";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Typography, Alert } from "@mui/material";
const Login = ({setIsAuthenticated, setUser, setOwners, setHub_supplier}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null); // New state for handling error messages
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      await getCsrfCookie();
      const response = await api.post('api/auth/login', {
        email,
        password,
      });
      // Assuming the API response has a token
      const { access_token, user, owners, hub_supplier } = response.data;
      // Store the JWT token in localStorage
      localStorage.setItem('token', access_token);
      localStorage.setItem('user', JSON.stringify(user)); 
      localStorage.setItem('owners', JSON.stringify(owners));
      localStorage.setItem('hub_supplier', JSON.stringify(hub_supplier));
      setErrorMessage(''); // Clear any previous errors
      setIsAuthenticated(true);
      setUser(user);
      setOwners(owners);
      setHub_supplier(hub_supplier);
      navigate("/dashboard");
    } catch (error) {
      console.error("Authentication failed:", error);
      localStorage.removeItem("token");
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data); // Set the error message if present in the error response
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <Box
      className="login-container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "primary.main",
        padding: 3,
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: 400,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Login
        </Typography>

        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <Box sx={{ mb: 2 }}>
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;