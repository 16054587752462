import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; 
import Login from './components/Login/Login';
import ProtectedRoute from './components/ProtectedRoute'; 
import Dashboard from './components/Dashboard';
import Header from "./components/header";
import ProductLayout from "./components/ProductLayout";
import Settings from "./components/settings";
import PublicRoute from "./components/PublicRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HubSettings from "./components/HubSettings";
import { OwnersProvider } from "./components/OwnersContext";
import SupplierSettings from "./components/SupplierSettings";
function App() {
  const [owners, setOwners] = useState(() => {
    
    const savedOwners = localStorage.getItem('owners');
    return savedOwners ? JSON.parse(savedOwners) : [];
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [hub_supplier, setHub_supplier] = useState(() => {
    const savedHs = localStorage.getItem('hub_supplier');
    return savedHs ? JSON.parse(savedHs) : null;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  return (
    <OwnersProvider>
    <Router>
      {isAuthenticated && <Header setIsAuthenticated={setIsAuthenticated} />} 
      <Routes>
        
        <Route 
          path="/login" 
          element={<PublicRoute component={() => <Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} setOwners={setOwners} setHub_supplier={setHub_supplier} />} isAuthenticated={isAuthenticated} />} 
        />

        <Route path="/dashboard" element={<ProtectedRoute component={() => <Dashboard currentUser={user.name} />} />} />
        <Route path="/settings" element={<ProtectedRoute component={() => <Settings />} />} />
        <Route path="/hub-settings/:hubId" element={<ProtectedRoute component={() => <HubSettings />} />} />
        <Route path="/supplier-settings/:supplierId" element={<SupplierSettings />} />
        {owners.map(owner => (
          <Route 
            key={owner.id} 
            path={`/products/${owner.acronym}`} 
            element={
              <ProtectedRoute component={() => (
                <ProductLayout owner={owner} />
              )} />
            }
          />
        ))}

        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
     <ToastContainer position="top-right" style={{ top: "60px" }} autoClose={5000} />
     </OwnersProvider>
  );
}

export default App;