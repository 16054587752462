import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import api from './api';

const FileUpload=({ owner, onUploadSuccess }) =>{
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [polling, setPolling] = useState(false);
  const [invalidRows, setInvalidRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  const submitFile = (e) => {
      e.preventDefault();

      if (!file) {
          setUploadStatus("Please select a file before uploading.");
          return;
      }

      const formData = new FormData();
      formData.append('file', file);

      api.post(`api/upload/${owner.id}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      })
      .then(response => {
        setUploadStatus(response.data.message);
        setPolling(true);
      })
      .catch(error => {
          setUploadStatus("File upload failed.");
      })
      .finally(() => {
          // Clear file state and input field
          setFile(null);
          if (fileInputRef.current) {
              fileInputRef.current.value = "";
          }
      });

      setUploadStatus('File is being processed!');
  };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    
        if (fileType !== 'csv' && fileType !== 'xls' && fileType !== 'xlsx') {
            setUploadStatus("Invalid file format. Please upload a .csv or .xls(x) file.");
            return;
        }
    
        setFile(file);
        setUploadStatus("");
    };
    useEffect(() => {
      let interval;
  
      if (polling) {
        interval = setInterval(() => {
          api
            .get(`api/upload-status/${owner.acronym}`)
            .then((response) => {
              if (response.data.status === 'completed') {
                setPolling(false);
                setUploadStatus("Upload completed!");
                setInvalidRows(response.data.invalid_rows || []);
                onUploadSuccess();
              }
            })
            .catch((error) => {
              setPolling(false);
              setUploadStatus("Error checking upload status.");
            });
        }, 1000); 
      }
  
      return () => clearInterval(interval);
    }, [polling, owner.acronym]);
  
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
      <Card sx={{ width: 350, height: 200, padding: 2 }}> {/* Unified dimensions */}
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Upload File
        </Typography>
        <form onSubmit={submitFile}>
          <Box sx={{ marginBottom: 2 }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button type="submit" variant="contained" color="primary">
              Upload
            </Button>
            {uploadStatus === "Upload completed!" && invalidRows.length > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleOpenModal}
              >
                View Invalid Rows
              </Button>
            )}
          </Box>
        </form>
        {uploadStatus && (
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
            {uploadStatus}
          </Typography>
        )}
      </CardContent>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh", 
            overflowY: "auto", 
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Invalid Rows
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 600, overflowY: "auto" }}
          >
          
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Row Number</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invalidRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.row_number}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Card>
      );
    
}

export default FileUpload;
