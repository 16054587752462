import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ExportButton from "./exportProducts";
import { Box, AppBar, Toolbar, Button, Typography, InputLabel } from "@mui/material";
import api from "./api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Settings, Logout } from '@mui/icons-material';
import { OwnersContext } from "./OwnersContext";
const Header = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentOwner = location.pathname.split("/")[2] || "Dashboard";
  const { owners } = useContext(OwnersContext);
  const handleLogout = () => {
    api.post("api/auth/logout").then((response) => {
      if (response.status === 200) {
        // Remove token from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('owners');
        setIsAuthenticated(false);
        // Navigate to the login page after successful logout
        navigate('/login');
      }
    });
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            SyncStock
          </Link>
        </Typography>

        {/* Suppliers Section */}
        <Box sx={{ border: 1, borderRadius: 1, borderColor: 'white', marginRight: 2, padding: 2, position: 'relative',marginTop: 2,marginBottom: 2 }}>
          <InputLabel
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '10px',
              background: '#1976D2',
              padding: '0 5px',
              fontSize: '0.75rem', // Matches the size of TextField label
              color: 'white',
            }}
          >
            Suppliers
          </InputLabel>
          {owners
            .filter((owner) => owner.state === 'supplier') // Filter for suppliers
            .map((owner) => (
              <Button
                key={owner.id}
                component={Link}
                to={`/products/${owner.acronym}`}
                color={currentOwner === owner.acronym ? "secondary" : "inherit"} // Highlight current owner
                variant={currentOwner === owner.acronym ? "contained" : "text"}
                sx={{ marginRight: 1 }}
              >
                {owner.acronym}
              </Button>
            ))}
        </Box>

        {/* Hubs Section */}
        <Box sx={{ border: 1, borderRadius: 1, borderColor: 'white', marginRight: 2, padding: 2, position: 'relative',marginTop: 2,marginBottom: 2  }}>
          <InputLabel
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '10px',
              background: '#1976D2',
              padding: '0 5px',
              fontSize: '0.75rem', // Matches the size of TextField label
              color: 'white',
            }}
          >
            Hubs
          </InputLabel>
          {owners
            .filter((owner) => owner.state === 'hub') // Filter for hubs
            .map((owner) => (
              <Button
                key={owner.id}
                component={Link}
                to={`/products/${owner.acronym}`}
                color={currentOwner === owner.acronym ? "secondary" : "inherit"} // Highlight current owner
                variant={currentOwner === owner.acronym ? "contained" : "text"}
                sx={{ marginRight: 1 }}
              >
                {owner.acronym}
              </Button>
            ))}
        </Box>

        <ExportButton />
        <div>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <AccountCircleIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleSettingsClick}>
              <Settings /> Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Logout /> Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
