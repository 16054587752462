import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, List, ListItem, ListItemText } from "@mui/material";

const AssociatedHubs = ({ supplierId }) => {
  const [associatedHubs, setAssociatedHubs] = useState([]);

  useEffect(() => {
    const fetchAssociatedHubs = () => {
    
      const hubSuppliers = JSON.parse(localStorage.getItem('hub_supplier')) || [];
      const owners = JSON.parse(localStorage.getItem('owners')) || [];

      const hubs = hubSuppliers
        .filter(hub => hub.suppliers.some(supplier => supplier.id === supplierId))
        .map(hub => {
          const hubOwner = owners.find(owner => owner.id === hub.id);
          return hubOwner ? hubOwner.name : `Hub ${hub.id}`;
        });

      setAssociatedHubs(hubs);
    };

    fetchAssociatedHubs();
  }, [supplierId]);

  if (associatedHubs.length === 0) {
    return null; 
  }

  return (
    <Card sx={{ width: 350, height: 200, padding: 2 }}> 
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Associated Hubs
        </Typography>
        <List>
          {associatedHubs.map((hubName, index) => (
            <ListItem key={index}>
              <ListItemText primary={hubName} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default AssociatedHubs;