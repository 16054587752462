import React, { useState } from "react";
import { Box, Typography, Card,CardContent,List, ListItem, ListItemText } from "@mui/material";
import ProductsPage from './list'; 
import FileUpload from './fileUpload'; 
import ExcelDownload from './excelDownload';
import SupplierSelector from './SupplierSelector';
import AssociatedHubs from "./AssociatedHubs";
import SupplierDownload from "./SupplierDownload";
const ProductLayout = ({ owner }) => {
  const [reload, setReload] = useState(false);
  const handleReload = () => setReload(!reload);
  return (
    <Box sx={{ padding: 3 }}> 
      <Typography variant="h4" gutterBottom>
        Products of {owner.name}
      </Typography>

      {owner.state==="hub" ? (
        <>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        <ExcelDownload owner={owner} />
        <SupplierSelector hubId={owner.id} /> 
      </Box>
     
      </>
      ) : (
        <>
        <Box
    sx={{
      display: "flex",
      alignItems: "flex-start", 
      justifyContent: "space-between", 
      gap: 2,
      mb: 2,
    }}
  >
        <FileUpload owner={owner} onUploadSuccess={handleReload} />
        <AssociatedHubs supplierId={owner.id} /> 
        <SupplierDownload owner={owner} />
        <Box
      sx={{
        width: 350, 
        height: 200, 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        padding: 2,
        bgcolor: "background.paper",
      }}
    >
      <Card sx={{ width: 350, height: 200, padding: 2 }}> 
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Info
        </Typography>
        <List>
         
            <ListItem key='1' >
              <ListItemText primary={owner.info} />
            </ListItem>

        </List>
      </CardContent>
    </Card>
    </Box>
        </Box>
        </>
      )}

      <ProductsPage owner={owner} reload={reload} />
    </Box>
  );
};

export default ProductLayout;