import React, { useState, useEffect } from "react";
import api from './api';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import { useLocation } from "react-router-dom";
function ExcelDownload({ owner }) {
  const [lastModifiedDate, setLastModifiedDate] = useState(null);

  useEffect(() => {
    const fetchLastModified = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await api.head(`/api/exports/${owner.id}?${timestamp}`);

        const dateHeader = response.headers['last-modified'];
        if (dateHeader) {
          setLastModifiedDate(new Date(dateHeader));
        }
      } catch (error) {
        console.error("Error fetching last modified date:", error.message);
      }
    };

    fetchLastModified();
  }, [owner.id]);

  const handleDownload = async () => {
    try {
      const response = await api.get(`/api/exports/${owner.id}`, {
        responseType: "blob", 
      });
  
      const filename = owner.settings.excel.filename;
  
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

  };
  return (
    <Card sx={{ textAlign: 'left', width: 350, height: 200, padding: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {owner.acronym} Stock File
        </Typography>

        <CardActions>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download Excel
          </Button>
        </CardActions>

        {lastModifiedDate && (
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            Last Upload: {formatDate(lastModifiedDate)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ExcelDownload;





