import React, { createContext, useState } from 'react';

export const OwnersContext = createContext();

export const OwnersProvider = ({ children }) => {
  const [owners, setOwners] = useState(() => {
    const savedOwners = localStorage.getItem('owners');
    return savedOwners ? JSON.parse(savedOwners) : [];
  });

  const updateOwners = (newOwners) => {
    setOwners(newOwners);
    localStorage.setItem('owners', JSON.stringify(newOwners)); 
  };

  return (
    <OwnersContext.Provider value={{ owners, updateOwners }}>
      {children}
    </OwnersContext.Provider>
  );
};