import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, FormControlLabel, Checkbox } from "@mui/material";

const SupplierSelector = ({ hubId }) => {
  const [suppliers, setSuppliers] = useState([]); // List of all suppliers
  const [selectedSuppliers, setSelectedSuppliers] = useState([]); // Suppliers associated with the hub

  useEffect(() => {
    // Fetch all suppliers and the ones associated with this hub
    const fetchSuppliers = async () => {
      try {
        const allOwners = JSON.parse(localStorage.getItem('owners')) || [];
        const supplierList = allOwners.filter(owner => owner.state === "supplier"); // Filter for suppliers only

        // Set suppliers to the filtered supplier list
        setSuppliers(supplierList);

        // Fetch associated suppliers for the specific hub from hub_supplier in localStorage
        const hubSuppliers = JSON.parse(localStorage.getItem('hub_supplier')) || [];
        const currentHubSuppliers = hubSuppliers.find(hub => hub.id === hubId)?.suppliers || [];

        setSelectedSuppliers(currentHubSuppliers.map(supplier => supplier.id));
      } catch (error) {
        console.error("Failed to load suppliers from localStorage:", error);
      }
    };

    fetchSuppliers();
  }, [hubId]);

  return (
    <Card sx={{ width: 350, height: 200, padding: 2 }}> {/* Adjust width and padding as needed */}
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Suppliers
        </Typography>
        <Box> {/* Adjust margin if needed */}
          {suppliers.map((supplier) => (
            <FormControlLabel
              key={supplier.id}
              control={
                <Checkbox
                  checked={selectedSuppliers.includes(supplier.id)}
                  disabled // Disables the checkbox
                />
              }
              label={supplier.acronym}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SupplierSelector;